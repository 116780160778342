<!-- Footer -->
<footer class="home-footer">

    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">
  
      <!-- Grid row -->
      <div class="row">
  
        <!-- Grid column -->
        <div class="col-md-4 mx-auto">
  
          <!-- Content -->
          
          <div class="social-connect">
            <a >
              <img class="text-zoom" src="./assets/images/windmill.png" style="margin-top:15px;" alt="" />
            </a>
            <h3 class="fw-400" style="color:#fff">Follow Us On</h3>
            <!--a href=""><i class="fa fa-twitter"></i></a-->
            <a href="https://www.facebook.com/CloudWindmill-Solutions-LLP-106058824553853/"><i class="fa fa-facebook text-zoom"></i></a>
            <a href="https://www.linkedin.com/company/cloudwindmill-solutions"><i class="fa fa-linkedin text-zoom"></i></a>
           
        </div>
  
        </div>
        <!-- Grid column -->
  
        <hr class="clearfix w-200 d-md-none">
  
        <!-- Grid column -->
        <div class="col-md-3 mx-auto">
  
          <!-- Links -->
          <h5 class="font-weight-bold text-uppercase mt-4 mb-4" style="color:#24a0ed">Articles & Info</h5>
  
          <ul class="list-unstyled">
            <li>
              <a [routerLink]="['/brochure']"  target="_top">CloudWindmill White Paper</a>
            </li>

            <li style="color:#0c86f8e1; cursor: pointer;" class="underline-on-hover">
              <a (click)="changeInNavigationHome('features')" target="_top"> Vision</a>
            </li>
            <li style="color:#0c86f8e1; cursor: pointer;" class="underline-on-hover">
            <a (click)="changeInNavigationHome('brochure-1')">Product Brochure</a>
            </li>
            <!-- <li style="color:#0c86f8e1; cursor: pointer;" class="underline-on-hover">
              <a (click)="changeInNavigationHome('blog')">Blogs</a>
            </li> -->
            <li>
              <a [routerLink]="['/termsAndConditions']"  target="_top">Terms & Conditions</a>
            </li>
  
          </ul>
  
        </div>
        <!-- Grid column -->
  
        <hr class="clearfix w-200 d-md-none">
  
        <!-- Grid column -->
        <div class="col-md-3 mx-auto">
  
          <!-- Links -->
          <h5 class="font-weight-bold text-uppercase mt-3 mb-4" style="color:#24a0ed">Resources</h5>
  
          <ul class="list-unstyled">
            <li style="color:#0c86f8e1; cursor: pointer;" class="underline-on-hover">
              <a (click)="changeInNavigationHome('price-compare')"> Cloud Price</a>
            </li>
          </ul>
  
        </div>
        <!-- Grid column -->
  
        <!--hr class="clearfix w-100 d-md-none"-->
  
        <!-- Grid column -->
        <!--div class="col-md-2 mx-auto">
  
          
          <h5 class="font-weight-bold text-uppercase mt-3 mb-4" style="color:whitesmoke">Links</h5>
  
          <ul class="list-unstyled">
            <li>
              <a href="#!">Link 1</a>
            </li>
            <li>
              <a href="#!">Link 2</a>
            </li>
            <li>
              <a href="#!">Link 3</a>
            </li>
            <li>
              <a href="#!">Link 4</a>
            </li>
          </ul>
  
        </div-->
        <!-- Grid column -->
  
      </div>
      <!-- Grid row -->
  
    </div>
    <!-- Footer Links -->
  
    <hr>
  
    <!-- Copyright -->
    <div class="text-center" style="color:whitesmoke">All Rights Reserved To CloudWINDMILL Solutions &nbsp; LLP &nbsp; 2021
      <p class="text-center white footer-text long" style="color:whitesmoke">
        Design and Developed by CloudWINDMILL solutions LLP
      </p>
    </div>
    <!-- Copyright -->
  
  </footer>
  <!-- Footer -->
