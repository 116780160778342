import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PricingComponent } from './pricing/pricing.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { BrochureComponent } from './brochure/brochure.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { MainComponent } from './components/main/main.component';
import { FooterComponent } from './shared/footer/footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Brochure1Component } from './brochure1/brochure1.component';
import { AboutUsComponent } from './about-us/about-us.component';


@NgModule({
  declarations: [		
    AppComponent,
    NavbarComponent,
    MainComponent,
    FooterComponent,
    PricingComponent,
    TermsAndConditionComponent,
    BrochureComponent,
      Brochure1Component,
      AboutUsComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCardModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    
  ],
  exports:[
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCardModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
