import { Component, OnInit, EventEmitter, Output, Inject, HostListener } from '@angular/core';
import { ViewportScroller, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public signInUrl=environment.baseUrl;
  public signUpUrl= environment.baseUrl+'signup';

  

  constructor(public viewPortScroller: ViewportScroller,
    public route: Router,
    public http: HttpClient,
  ) { }
    hover:boolean = false

  
    @Output() eventShowIsFalse = new EventEmitter<any>();
  
    changeInNavigationHome(type: any) {
      this.route.navigate(['home'])
      this.changeInNavigation(type)
    }
  
    changeInNavigation(type: any) {
      console.log(type);
      if (type == 'features')
      {
        //alert("you are clicking Vision")
        this.viewPortScroller.scrollToAnchor(type);
      }
      else if (type == 'contact-us')
      {
        this.viewPortScroller.scrollToAnchor(type);
      }
      else if (type == 'zero-banner')
      {
        this.viewPortScroller.scrollToAnchor(type);
      }
      else if (type == 'second-banner')
      {
        this.viewPortScroller.scrollToAnchor(type);
      }
      else if (type == 'third-banner')
      {
        this.viewPortScroller.scrollToAnchor(type);
      }
      else if (type == 'fourth-banner')
      {
        this.viewPortScroller.scrollToAnchor(type);
      }
      else if (type == 'fifth-banner')
      {
        this.viewPortScroller.scrollToAnchor(type);
      }
      else if (type == 'blog')
      {
        this.viewPortScroller.scrollToAnchor(type);
      }
      else if (type == 'use')
      {
        this.viewPortScroller.scrollToAnchor(type);
      }
      else
        this.route.navigate([type])
    }
  
    // @HostListener('window:scroll', ['$event'])
    // onWindowScroll(e) {
    //   let element = document.getElementById('navbar');
    //   let sticky = element.offsetTop;
    //   let img = document.getElementById('logo');
      
    //   if (window.pageYOffset >=100) {
    //      console.log("100");
    //     element.classList.add('sticky');
        
    //   } else {
    //     element.classList.remove('sticky');
    //     console.log("not 100");
        
    //   }
    // } 

    ngOnInit() {
    }
  
  }
