import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

// interface GlobalEnvironment {
//   process : Process
// }

// interface Process {
//   env : Env
// }

// interface Env {
//   IAMURL : string
// }

// declare var process: Process;

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  // for local testing
 // public loginUrl = "http://localhost:8081/"
//  public sitekey = "6Lf61r8ZAAAAANzF0vijJFtmaAVW_cz9kJREHvwj"
 

// for server website
//public loginUrl = "https://cloudwindmill.com/iam/"
 public loginUrl = "https://optima.cloudwindmill.io/iam/"
//public loginUrl = process.env.IAMURL
public sitekey = "6LeNFPUcAAAAAENslp76ISHQFPzJC3nsi_wmHeQ-"

  public headers(headers: any = {}) {
    let token = localStorage.getItem('token');
    if (headers) {
      return new HttpHeaders(headers);
    } else {
      if (token == null) {
        headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      } else {
        headers = new HttpHeaders({
          // 'X-Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        });
      }
      return headers;
    }
  };

  login(portal, userid, password) {
    let payload: any = {}
    payload = { portal: portal, user_id: userid, password: password }
    this.http.post<any>(this.loginUrl + 'login/a61215e4ca1df7b4cfd72dab7ec4a2fd', payload).subscribe(resp => {
      if (resp.MAIN.RESULT.RESPONSE.token != '') {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('optimatoken', resp.MAIN.RESULT.RESPONSE.token);
        setTimeout(() => {
          this.document.location.href = resp.MAIN.RESULT.RESPONSE.redirect_url
        }, 1000);
      }
    })
  }
  get_all_blogs(body:any){
    
    return this.http.post(this.loginUrl+'getBlogContent',body);
  }

  get_single_blog(blog_id:string){
    const params = new HttpParams()
    .set('id', blog_id);
    return this.http.get(this.loginUrl +'getSingleBlog' , { params });
  }

}
