<!--==================== Start: Top banner ====================-->
<div class="wrapper hero" >
  <div class="main">
    <!-- <div class="row align-items-center justify-content-md-center"> -->
      <!-- <div class="col-md-12" >
        <h2 class="an-1 text-center ease delay" style="color: rgb(9, 248, 248);">WindMill's OPTIMA - The Cloud Cost Optimization</h2><br>
      </div> -->

        <div id="myCarousel" class="carousel slide" data-ride="carousel" >

          <ol class="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
            <li data-target="#myCarousel" data-slide-to="1"></li>
            <li data-target="#myCarousel" data-slide-to="2"></li>
            <li data-target="#myCarousel" data-slide-to="3"></li>
          </ol>

          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <div class="media">
                <div class="media-body ml-auto mr-auto text-center">
                  <div class="right-content text-center">
                    <img src="./assets/images/Picture1.svg" class="img-fluid crsl-img">
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="media">
                <div class="media-body media-middle ml-auto mr-auto text-center">
                  <div class="right-content text-center">
                    <img src="./assets/images/Picture2.png" class="img-fluid crsl-img">
                  </div>
                </div>
              </div>
            </div>    
            <div class="carousel-item">
              <div class="media">
                    <img src="./assets/images/Picture3.svg" class="img-fluid crsl-img">
              </div>
            </div>
            <div class="carousel-item">
              <div class="media">
                  <img src="./assets/images/Picture4.svg" class="img-fluid crsl-img">
              </div>
            </div>
          </div>
        </div>
        <div class="hero-content mt-3">  
          <a type="button" class="btn btn-outline-info btn-md start-btn" href="{{signInUrl}}">START YOUR TOURS</a>
        </div><br>
  </div>
</div>

<!--==================== Start: Top banner ====================-->
<!--==================== Start: Zero banner ====================-->
<section  id="zero-banner" >
    <div class="container-fluid">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6 col-lg-6 col-sm-12 ">
          <div class="right-content text-center zoom" data-aos="zoom-in-left">
              <img src="./assets/images/Picture5.svg" class="img-fluid">
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="left-content my-3">
            <h3 class="text-zoom">WINDMILL <b>Optima</b> Cloud Cost Optimization</h3>
          <p>
            WINDMILL Optima manages AWS, Azure and GCP and provide single or easy interface to manages all cloud resources 
          </p>
          <div class="py-3 px-0">
            <div class="text-zoom ">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Resource Scheduling
            </div>
            <div class="text-zoom ">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Right Sizing 
            </div>
            <div class="text-zoom ">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Right Recommendation
            </div>
            <div class="text-zoom ">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Governance Guideline
            </div>
            <div class="text-zoom ">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Team/User Management  
            </div>
          </div>
          <a type="button" class="btn btn-outline-info btn-md btn-rounded waves-effect start-btn" href="{{signInUrl}}">Start here..</a>
          </div>
        </div>
      </div>
    </div>
</section>

<!--==================== Start: Second banner ====================-->
<section class="second-hero bg-white" id="second-banner">
  <div class="container-fluid">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-6">
        <div class="left-content my-3">
          <h3 class="text-zoom">WindMill <b>Optima</b> Cloud Aggregator to focus cost saving</h3>
          <p class="text-zoom">
            WindMill <b>Optima</b> keep a close eye on cloud cost
          </p>
          <ul class="icon-list">
            <li class="text-zoom ">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Schedule Resources on demand
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Single interface for all public cloud
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Non intrusive appoach for VM resources
            </li>
            <li class="text-zoom ">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Notification for each action
            </li>
            <li class="text-zoom ">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Support cloud services e.g. Virtaul MAchines, Volumes, RDS
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <div class="right-content zoom" data-aos="zoom-in-left">
            <img src="./assets/images/Picture6.svg" class="img-fluid" > 
        </div>
      </div>
    </div>
  </div>
</section>
<!--==================== End: Second banner ====================-->

<!--==================== Start: Third banner ====================-->
<section class="third-hero" id="third-banner">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="right-content1 text-center zoom" data-aos="zoom-in-right">
            <img src="./assets/images/Picture7.svg" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <div class="left-content my-3">
          <h3 class="text-zoom">WindMill <b>Optima</b> Cloud Cost optimization supporting AWS, Azure and Google Cloud</h3>
          <p class="text-zoom">
            Contineous providing support for customer cloud environment 
          </p>
          <ul class="py-3 px-0">
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Instance distribution view
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Score utilization view for all providers  
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Daily cost across all providers
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Cost distribution view for all providers
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Total expected cost for current week
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!--==================== End: Third banner ====================-->

<!--==================== Start: Fourth banner ====================-->
<section class="second-hero bg-white" id="fourth-banner">
  <div class="container-fluid">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-6">
        <div class="left-content my-3">
          <h3 class="text-zoom">WindMill - Dynamic Schedule to provide flexibility in team to manage resources effectively</h3>
          <p class="text-zoom">
            You can build single schedule which can be applied to - AWS, AZURE and GCP
          </p>
          <ul>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Schedule Management
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Recommended, dynamic, active/suspend/resume Schedule
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Utilization Score of resources usage
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Cost and saving as per schedule
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg"  class="img-fluid">
              </span>
              Current week schedule viewing and notifications
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <div class="right-content zoom" data-aos="zoom-in-left">
            <img src="./assets/images/Picture8.svg" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>
<!--==================== End: Fourth banner ====================-->

<!--==================== Start: Fifth banner ====================-->
<section class="third-hero" id="fifth-banner">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="right-content zoom" data-aos="zoom-in-left">
            <img src="./assets/images/Picture9.svg" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <div class="left-content my-3">
          <h3 class="text-zoom">WindMill - RDS & Volumes support</h3>
          <p class="text-zoom">
            Optima provides analytics for RDS and Volumes to drive cost optimization for AWS, Azure and GCP</p>
          <ul class="py-3 px-0">
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              DB instance support across all cloud
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              RDS recommendation for storage & CPU
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Volumes support across all cloud
            </li>
            <li class="text-zoom">
              <span class="icon">
                <img src="./assets/images/check.svg" class="img-fluid">
              </span>
              Volumes attached/unattached recommendation
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!--==================== End: Fifth banner ====================-->

<!--==================== Start: Features banner ====================-->
<section id="features">
  <div class="features text-center bg-white py-5">
    <div class="container-fluid">
      <div class="row " >
        <div class="col-md-12">
          <h3 class="title fw-400 mb-1"><b>WindMill</b> vision for Cloud Infrastructure</h3>
        </div>

        <div class="col-md-6 mt-4">
          <div class="media text-zoom">
            <div class="media-left">
              <div class="feature-icon">
                <img src="./assets/images/web.png" class="img-fluid" />
              </div>
            </div>
            <div class="media-body text-left">
              <h4 class="media-heading  long"><b>Zero Cost for unused resource</b></h4>
              <p>
                AI based resource scheduling prevent unused resource to stop and no impact on productivity
              </p>
            </div>
          </div>
          <div class="media text-zoom" style="margin-top:-90px">
            <div class="media-left">
              <div class="feature-icon">
                <img src="./assets/images/offline.png" class="img-fluid" >
              </div>
            </div>
            <div class=" media-body text-left">
              <h4 class="media-heading  long"><b>Zero CLI</b></h4>
              <p>
                Complete UI drag and drop operation. Abstract Everything
              </p>
            </div>
          </div>

          <div class="media text-zoom" style="margin-top:-90px">
            <div class="media-right">
              <div class="feature-icon">
                <img src="./assets/images/wizard.png" class="img-fluid" />
              </div>
            </div>
            <div class=" media-body text-left">
              <h4 class="media-heading  long"><b>Zero Trust leads full security</b></h4>
              <p>
                Zero trust to external world. Roles and permissions are mapped to enterprise identity management
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="media text-zoom">
            <div class="media-left">
              <div class="feature-icon">
                <img src="./assets/images/technology.png" class="img-fluid"  >
              </div>
            </div>
            <div class="media-body text-left">
              <h4 class="media-heading  long"><b>Zero human latency</b></h4>
              <p>
                Automate and orchestrate all apps as pipeline (Build, Test, Deployment)
              </p>
            </div>
          </div>

          <div class="media text-zoom" style="margin-top:-90px">
            <div class="media-right">
              <div class="feature-icon">
                <img src="./assets/images/gps.png" class="img-fluid" />
              </div>
            </div>
            <div class="media-body text-left">

              <h4 class="media-heading  long"><b>Zero Appliances</b></h4>
              <p>
                Multi cloud support eliminate need to have local infra
              </p>
            </div>
          </div>

          <div class="media text-zoom" style="margin-top:-90px">
            <div class="media-right">
              <div class="feature-icon">
                <img src="./assets/images/lock.png" class="img-fluid"  />
              </div>
            </div>
            <div class=" media-body text-left">
              <h4 class="media-heading  long"><b>Zero Capacity Constraints</b></h4>
              <p>
                All provisioning via automated Scaling, Aging and Suspension policy
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
  </div>
</section>
<!--==================== End: Features banner ====================-->

<!--==================== Start: Testimonials banner ====================-->

<!--==================== End: Testimonials banner ====================-->

<!--==================== Start: How To Use banner ====================-->
<section id="use">
  <div class="use">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <h3 class="text-white text-center">Business opportunity to use <b>Cloud</b>WindMill
          </h3>
        </div>

        <div class="col-md-4 col-sm-6">
          <ul class="use-list text-white">
            <h4 class="white-long">Software Service Enterprises</h4>
            <li>Managing large number of VMs for Dev, QA and Production</li>
            <li>Cloud agnostic app arch help to stay multi cloud</li>
            <li>Create reusable Application Components</li>
          </ul>
          <br>
          <ul class="use-list text-white">
            <h4 class="white-long">Cloud Aspiring MSME</h4>
            <li>Not complicated as clears the clutter </li>
            <li>Provide security as per guideline of AWS, Azure and GCP</li>
            <li>Provide new way to do business with cost optimization factor</li>
          </ul>

        </div>
        <div class="col-md-4 col-sm-6">
          <ul class="use-list text-white">
            <h4 class="white-long">Enterprises looking to unlocking cloud vendor</h4>
            <li>Cloud agnostic application blueprint helps to deploy app on any cloud</li>
            <li>Cloud migration as easy as installing software update</li>
            <li>Dev team focus on app dev not in infra</li>
          </ul>
          
          <ul class="use-list text-white">
            <h4 class="white-long">Self IT/ITES Managed Enterprises</h4>
            <li>Large IT operations on Cloud</li>
            <li>Hybrid and multi cloud management</li>
            <li>SSO/SAML/oAuth/IDP management</li>
          </ul>

        </div>
        <div class="col-md-4 col-sm-6">
          <ul class="use-list text-white">
            <h4 class="white-long">Individual Contributor</h4>
            <li>Individual can login for multiple tenant/organization </li>
            <li>Individual can managed service for multiple customer </li>
            <li>Proven reality for “Infrastructure on Palm” </li>
          </ul>
         <br>
          <ul class="use-list text-white">
            <h4 class="white-long">Shared Web Service Enterprises</h4>
            <li>Cloud cost tracking and used VMs as per utilization</li>
            <li>Scheduling Dev and QA effectively to tap on cloud cost</li>
            <li>Easy cloud migration leverages to manage apps on hybrid cloud env</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!--==================== End: How To Use banner ====================-->

<!--==================== End: Contact us form ====================-->
  <section class="container-fluid" id="contact-us">
    <div class="row justify-content-md-center">
      <div class="col-md-12">
        <div class="text-center position-relative">
          <h2 class="ease delay fw-400">
            Contact Us
          </h2>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="contact-content mt-4">
          <p>
            Please provider your detail, we will get back to you ASAP..
          </p>
          <form>
            <div class="form-group">
              <input type="text" name="name" id="name"  placeholder="Name" class="form-control cfc" [(ngModel)]="message.name">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="mobile" id="mobile" placeholder="mobile(optional)" [(ngModel)]="message.mobile">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="organization" id="organization" placeholder="organization" [(ngModel)]="message.organization">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="email" id="email" placeholder="email" [(ngModel)]="message.email">
            </div>
            <div class="form-group">
              <textarea name="message" id="message" placeholder="message(optional)" rows="4" class="form-control cfc"
                [(ngModel)]="message.message"></textarea>
            </div>
            <div class="form-group">
              <re-captcha  (resolved)="resolved($event)"  siteKey={{sitekey}}></re-captcha>
            </div>
            <div class="form-group">
              <p class="text-danger">{{responseMessage}}</p>
            </div>
            
            <div class="form-group d-block">
              <button class="btn btn-info" type="button" (click)="fnSubmit()" name="btnContactus" >SEND
                MAIL</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>


<!-- ====================================Blog start============================================= -->
<!-- <section class="third-hero" id="blog">
    <div class="card m-3">    
      <h3 class="card-header text-center">Blogs</h3>    
      <div class="card-body">    
        <div class="row flex wrap">
          <div class="col-md-4 col-sm-4" *ngFor="let blog of all_blogs | paginate:{id:'listing_pagination',
                      itemsPerPage:3,currentPage:page,totalItems:10}">
          <div class="card h-100 zoom" >
            <div class="card-header">
            <h4 class="card-title" [routerLink]="['/blogs/blog',blog.ID]">{{blog.title}}</h4>
            <h6 class="card-text" >{{blog.createddate|date}}</h6>
          </div>
          <img class="card-img-top" [src]="blog.imagename" alt="log Images">
          <div class="card-body">
          <p [innerHTML]="blog.content|slice:0:300"></p>
          <button type="button" class="btn btn-outline-info btn-rounded"[routerLink]="['/blogs/blog',blog.ID]">Read more</button>
        </div>
        </div>
        </div>
        </div>    
      </div>    
      <div class="card-footer pagination justify-content-end " >
        <pagination-controls  id="listing_pagination" maxSize="5" directionLinks="true" (pageChange)="hello(page=$event)" ></pagination-controls>  
      </div>    
    </div> 
    <br>
</section> -->
