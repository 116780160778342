<div class="container-fluid">
  <div class="row justify-content-center" style="margin-top: 10vh;">
    <div class="col-md-11 text-center" style="margin-bottom: 50px;">
      <img src="./assets/images/Picture24.png" class="img-fluid">
      <h3 class="title text-center">Know about us</h3>
      <p class="text-center about">CloudWINDMILL Solutions is product platform, which aggregates your cloud resources in one 
        view and helps you schedule your resources more efficiently as per need. Scheduling underutilized resources
         more efficiently using AI can generate 20% to 30% savings in cloud cost.</p>
         <p class="text-center about">It provides flexibility to your
          application to migrate on any public cloud using automation.</p>
    </div>
  </div>
  <div class="row justify-content-center" style="margin-bottom: 50px;">
    <div class="col-md-5">
      <div class="card h-100">
        <div class="rounded shadow-sm py-3 px-4 text-center">
          <img src="./assets/images/rakeshkhandelwal.svg" class="img-fluid">
          <h4 class="mb-0">Rakesh Khandelwal</h4><span class="small text-uppercase text-muted">Founder</span>
          <ul class="social mb-0 list-inline mt-3">
            <li class="list-inline-item"><a href="https://www.facebook.com/rakesh.khandelwal.9256" class="social-link"><i class="fa fa-facebook-f"></i></a></li>
            <li class="list-inline-item"><a href="https://www.linkedin.com/in/rakesh-khandelwal-a410592" class="social-link"><i class="fa fa-linkedin"></i></a></li>
          </ul>
          <div>
            <p class="small text-uppercase text-muted">Rakesh Khandelwal has more than 20+ yrs experience in S/W IT industry, 
              he has spent most of professional experince in TCS from programmer to management role  
              and has always engaged in R&D related project. Rakesh founded CloudWINDMILL in 2019, 
              and has developed SaaS based public cost optimization product. 
              CloudWINDMILL's OPTIMA product platfrom registered as IPR and innovation for cloud strategy.
            Rakesh is cloud strategic consultant for enterprise cloud strategy.  </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="card h-100">
        <div class="rounded shadow-sm py-3 px-4 text-center">
          <img src="./assets/images/dinesh.jpg" class="img-fluid circular--square">
          <h4 class="mb-0">Dinesh Taskar</h4><span class="small text-uppercase text-muted">Senior Developer</span>
          <ul class="social mb-0 list-inline mt-3">
            <li class="list-inline-item"><a href="#" class="social-link"><i class="fa fa-facebook-f"></i></a></li>
            <li class="list-inline-item"><a href="#" class="social-link"><i class="fa fa-linkedin"></i></a></li>
        </ul>
        <div class="card-text">
          <p class="small text-uppercase text-muted">Dinesh Taskar has more than 1+ yrs experience in software development. 
            He has experience Angular and microservices. 
            </p>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>