import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { BrochureComponent } from './brochure/brochure.component';
import { MainComponent } from './components/main/main.component';
import { Brochure1Component } from './brochure1/brochure1.component';
import { AboutUsComponent } from './about-us/about-us.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', },
  { path: 'price-compare', loadChildren: () => import('./price-compare/price-compare.module').then(mod => mod.PriceCompareModule) },
  { path: 'home', component: MainComponent },
  { path: 'brochure', component: BrochureComponent},
  { path: 'brochure-1', component: Brochure1Component},
  { path: 'about-us', component: AboutUsComponent},  
  { path: 'termsAndConditions', component: TermsAndConditionComponent},
  { path: 'password', loadChildren: () => import('./create-password/create-password.module').then(mod => mod.CreatePasswordModule) },
  { path: 'blogs', loadChildren: () => import('./blogging/blogging.module').then(mod => mod.BloggingModule)},
  // { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
