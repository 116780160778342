import { Component, OnInit } from '@angular/core';
import { ViewportScroller, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {

  constructor( public viewPortScroller: ViewportScroller,public route: Router) { }
  termsObject: any = [
       { name: 'General', path: '/general', id: 'general' },
       { name: 'Introduction', path: '/intro', id: 'intro' },
       { name: 'Signing Up', path: '/signup', id: 'signup' },
       { name: 'Windmill services detail', path: '/services', id: 'services' },
       { name: 'Restriction of Windmill services', path: '/restriction', id: 'restriction' },
       { name: 'Windmill services Terms', path: '/terms', id: 'terms' },
       { name: 'Limitation of Liability', path: '/limitation', id: 'limitation' },
       { name: 'Ownership of Intellectual Property', path: '/ownership', id: 'ownership' },
       
   
     ]
  ngOnInit() {
  }
  changeInNavigationHome1(type: any) {
    this.route.navigate(['termsAndConditions'])
    this.changeInNavigation1(type)
  }

  changeInNavigation1(type: any) {
    console.log(type);
    if (type == 'general')
    {
      //alert("you are clicking Vision")
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'intro')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'signup')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'services')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'restriction')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'terms')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'limitation')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'ownership')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    // else if (type == 'pricing')
    //   this.route.navigate(['password'], { queryParams: { skey: '$2y$04$u.BM5wVE5GpJ_4Y_ZHhi2ej_nW8DvTdCz5dTvvMlHZcnRMDh4Mpgy' } })
    else
      this.route.navigate([type])
  }
}
