
<div class="container-lg">
  <div class="card mt-5" >
    <div class="card-img-top">
    <div class="row  justify-content-md-center">
      <div class="col-md-6 text-center col1" >
        <div style="background-color: white;">
          <img src="./assets/images/windmill.png">
        </div>
        <div class="title text-uppercase ">
          <h5 ><b>the cloud management tool</b></h5>
        </div>
        <div class="text-center">
          <h6 class="title text-uppercase">overview</h6>
          <p class="clr"><b>CloudWINDMILL Solutions is offering OPTIMA as Product,
              Which aggregates your cloude resources in one view and
              helps you schedule your resources more efficiently as per need.
              Scheduling underutilized resources more efficiently using AI can 
              generates 20% to 30% saving in cloud cost.</b>
          </p>
          <p class="clr"><b>
            CloudWINDMILL Solutions is conceptualized Kubernetes and Host based
            application deployment platform. It provides flexibility to your 
            application to migrate on any public cloud using automation.</b>
          </p>
        </div>
        <div>
          <img src="./assets/images/Picture25.png" class="img-fluid">
        </div>
      </div>

      <div class="col-md-6 col1" >
        <div class="title text-uppercase" >
          <h4 >the cloud management tool</h4>
        </div>
        <div class="media">
          <span class="icon">
          <i class="fa fa-check mr-1 clr"></i>
          </span>
          <div class="media-body">
          <p class="mt-0 clr">Zero cost for unused resouce: AI based resource scheduling
          prevent unused resource to stop and no impact on productivity.</p>
        </div>
        </div>
        <div class="media">
          <span class="icon clr">
          <i class="fa fa-check mr-1"></i>
          </span>
          <div class="media-body">
          <p class="clr">Zero trust on external worlds. Roles and permission mapped to 
             enterprise identity management.</p>
          </div>
        </div>
        <div class="media">
          <span class="icon clr">
          <i class="fa fa-check mr-1"></i>
          </span>
          <div class="media-body">
          <p class="clr">Zero applience. Multi and hyrid cloud support complete pipeline.</p>
          </div>
        </div>
        <div class="media">
          <span class="icon clr">
          <i class="fa fa-check mr-1"></i>
          </span>
          <div class="media-body">
          <p class="clr">Zero CLI. Complete UI drag and drop operation. abstract everything.</p>
          </div>
        </div>
        <div class="media">
          <span class="icon clr">
          <i class="fa fa-check mr-1"></i>
          </span>
          <div class="media-body">
          <p class="clr">Zero human latency, automate and orchestrate all apps as pipeline 
            (Build, Test, Deployment)</p>
            </div>
        </div>
        <div class="media">
          <span class="icon clr">
          <i class="fa fa-check mr-1"></i>
          </span>
          <div class="media-body">
          <p class="clr">Zero capacity contraints. All provisioning via autometed scalling.
            Aging and Suspension policy.
          </p>
          </div>
        </div>
        <div class="title">
          <h4 class="clr">Why CloudWINDMILL?</h4>
        </div>
        <div class="row">
          <div class="col-3 text-center">
            <img src="./assets/images/clipboard-solid.svg" class="img-fluid imgicon">
            <!-- <i class="fa fa-clipboard" aria-hidden="true"></i><br> -->
          <p class="clr"><small>Accelerate Organization Productivity</small></p>
          </div>
          <div class="col-3 text-center">
            <img src="./assets/images/cloud-upload-alt-solid.svg" class="img-fluid imgicon">
         <p class="clr"><small>Standardized Cloud Uses</small></p>
          </div>
          <div class="col-3 text-center">
          <img src="./assets/images/sync-alt-solid.svg" class="img-fluid imgicon">
          <p class="clr"><small>360 Health Mnitoring & Analytics</small></p>
          </div>
          <div class="col-3 text-center">
            <img src="./assets/images/network-wired-solid.svg" class="img-fluid imgicon">
          <p class="clr"><small>Accelerate Organization Productivity</small></p>
          </div>
          <div class="col-3 text-center">
            <img src="./assets/images/dollar-sign-solid.svg" class="img-fluid imgicon">
          <p class="clr"><small>Cost Control</small></p>
          </div>
          <div class="col-3 text-center">
            <img src="./assets/images/history-solid.svg" class="img-fluid imgicon">
          <p class="clr"><small>Frequent Release, CI/CD</small></p>
          </div>
          <div class="col-3 text-center">
            <img src="./assets/images/clone-solid.svg" class="img-fluid imgicon">
          <p class="clr"><small>Multi Tenency/SAML/SSO/IDP</small></p>
          </div>
          <div class="col-3 text-center">
            <img src="./assets/images/shield-alt-solid.svg" class="img-fluid imgicon">
          <p class="clr"><small>Security Inherited from cloud providor</small></p>
          </div>
          <div class="col-3 text-center">
            <img src="./assets/images/quinscape-brands.svg" class="img-fluid imgicon">
          <p class="clr"><small>Reduce Vendor Lock-in</small></p>
          </div>
          <div class="col-3 text-center">
            <img src="./assets/images/autoprefixer-brands.svg" class="img-fluid imgicon">
          <p class="clr"><small>Autometed Verification, Validation & Rollback</small></p>
          </div>
        </div>
    </div>
  </div>



  <div class="row  justify-content-md-center">
    <div class="col-md-6 col1" >
      <div class="title text-uppercase">
        <h4 class="clr">WHAT IS CloudWINDMILL'S OPTIMA?</h4>
      </div>
      <div class="media">
        <span class="icon">
        <i class="fa fa-check mr-1 clr"></i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Cloud Agnostics View.</p>
      </div>
      </div>
      <div class="media">
        <span class="icon">
        <i class="fa fa-check mr-1 clr"></i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Auto Parking.</p>
      </div>
      </div>
      <div class="media">
        <span class="icon">
        <i class="fa fa-check mr-1 clr"></i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Right Sizing.</p>
      </div>
      </div>
      <div class="media">
        <span class="icon">
        <i class="fa fa-check mr-1 clr"></i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Wast Management.</p>
      </div>
      </div>
      <div class="media">
        <span class="icon">
        <i class="fa fa-check mr-1 clr"></i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Storage-Disk  Data In/Out.</p>
      </div>
      </div>
      <div class="media">
        <span class="icon">
        <i class="fa fa-check mr-1 clr"></i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Network Packet In/Out.</p>
      </div>
      </div>
      <div>
        <img src="./assets/images/Picture26.png" class="img-fluid">
      </div>
      <div class="title text-uppercase">
        <h4 class="clr">featuers</h4>
      </div>
      <div class="media">
        <span class="icon clr">
        <i>1.</i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Public Cloud Support(AWS,GCP,Azure).</p>
      </div>
      </div>
      <div class="media">
        <span class="icon clr">
        <i>2.</i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Security as per public cloud 3rd party management.</p>
      </div>
      </div>
      <div class="media">
        <span class="icon clr">
        <i>3.</i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Dynamic onboarding and off boarding od resource/providers.</p>
      </div>
      </div>
      <div class="media">
        <span class="icon clr">
        <i>4.</i>
        </span>
        <div class="media-body">
        <p class="mt-0 clr">Notification for current week(hourly,daily,weekly).</p>
      </div>
      </div>
    </div>
    <div class="col-md-6 col1" >
      <div>
        <h4 class="title text-uppercase">summary</h4>
        <p class="clr">
          The CloudWINDMILL is proven reality for "Infrastructure on Palm",
          organization can manages their cloud infra as per need, 
          The CloudWINDMILL is one stop solution for software devlopment
           enterprises, self IT manage enterprises(includes many application), 
           entreprises managing shared web services and last but not the least 
           for the free lancer. The CloudWINDMILL enables software developers to 
           focus on devlopment and not on deployement. Enterprise can save cost on 
           development to provide the effective infra as per intent. Policy based deployment 
           control orchestrate infra as per need/schedule/load. 
           Role base analytics and dashboard has elevation view on usage plan, cost,
           infra, progress, rollback etc...
        </p>
      </div>
      <div>
        <h4 class="title text-uppercase clr">get in touch</h4>
          <p class="clr"><i>info@cloudwindmill.io</i><br>
          <i >+91-9999034842</i><br>
          <a href="http://www.cloudwindmill.io">http://www.cloudwindmill.io</a></p> 

      </div>
    </div>
  </div>
</div>
</div>
</div>