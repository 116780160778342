import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
declare var anime: any;
import { fadeInAnimation } from '../../animations/fade-in.animation';
import AOS from 'aos';

interface Blog{
  ID:string
  title:string,
  content:string,
  imagename:string,
  createdby: string,
  createddate:string
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '','class': 'wm-theme-colors' }
})
export class MainComponent implements OnInit {
  public all_blogs: Blog[] = [];
  data1: Array<any>;
  totalRecords: string;
  page: number = 1;
  public signInUrl=environment.baseUrl;
  constructor(public viewPortScroller: ViewportScroller, private http: HttpClient, private data: DataService)
  { 
    this.all_blogs = new Array<any>();
    
  }
  animating() { console.log('Animating...'); }
  animated() { console.log('Animated'); }

  log(msg) { console.log(msg); }

  changeInNavigation(type: any) {
    console.log(type);
    this.viewPortScroller.scrollToAnchor(type);

  }
  sitekey:any
  message: any = {}
  responseMessage: any
  recaptcha:any[]
  recaptchaValidation:boolean = false 
  resolved(recaptchaResponse:any[]){
    this.recaptcha = recaptchaResponse
    //console.log(this.recaptcha)
    //alert(this.recaptcha)
    this.fnValidateRecaptchaToken(this.recaptcha)
  }

  // -------------Animation method---------------------
  // ngAfterViewInit(): void {
  //     // Wrap every letter in a span
  // const textWrapper = document.querySelector('.an-1');
  // textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

  // anime.timeline({loop: true})
  // .add({
  //   targets: '.an-1 .letter',
  //   scale: [4,1],
  //   opacity: [0,1],
  //   translateZ: 0,
  //   easing: "easeOutExpo",
  //   duration: 950,
  //   delay: (el, i) => 70*i
  // }).add({
  //   targets: '.an-1',
  //   opacity: 0,
  //   duration: 1000,
  //   easing: "easeOutExpo",
  //   delay: 1000
  // });
  // }


  fnValidateRecaptchaToken(recaptchaToken:any[]){
    let payload = { recaptcha: this.recaptcha };
    this.http.post<any>(this.data.loginUrl + 'validateRecaptcha', payload).subscribe(resp => {
      console.log(resp);
      if (resp.DATA.RESPONSE_CODE == 200){
        //alert("reCaptcha response code 200 received")
      this.recaptchaValidation = true
      setTimeout(() => {
        this.responseMessage = ''

      }, 2000);
    } else{
      //alert(resp.DATA.RESPONSE)
      this.responseMessage = resp.DATA.RESPONSE
      this.recaptchaValidation = false
    }
    })
  }
  fnSubmit() {
    console.log(this.message);
    if (this.message.message == '')
      this.message.message = ""
    if (this.message.mobile == '')
      this.message.mobile = ""
    let payload = { name: this.message.name, email:this.message.email,message:this.message.message, organization:this.message.organization,mobile:this.message.mobile };
    if (this.recaptchaValidation == true) {
      //alert("reCaptcha is validated from server")
      this.http.post<any>(this.data.loginUrl + 'contactUs', payload).subscribe(resp => {
        console.log(resp);
        if (resp.DATA.RESPONSE_CODE == 200){
          this.responseMessage = resp.DATA.RESPONSE
          // setTimeout(() => {
          //   this.responseMessage = ''
          //   this.message.name = ''
          //   this.message.email = ''
          //   this.message.message = ''
          // }, 2000);
        } else{
          this.responseMessage = resp.DATA.RESPONSE
        }
      })
   } else{
    //alert("Pls validate the reCaptcha first...")
    this.responseMessage = "Pls validate the reCaptcha first..."
   }
  }
  
  ngOnInit() {
    AOS.init();
    this.sitekey = this.data.sitekey
    this.increament = 0;
    this.load_all_blogs();
  }

  load_all_blogs() {
        const body = {
          limit: 3,
          offset: 0
        }
        ///Dinesh to implemetation pagination here with horizental <> sign for more blogs
    
        this.data.get_all_blogs(body).subscribe((response:any)=>{
          response.DATA.forEach((element:any) => {
            this.all_blogs.push(element);
          });
        })
  }
  private increament: any
  increment_blog() {
        const body = {
          limit: 3,
          offset: this.increament+1
        }
        this.data.get_all_blogs(body).subscribe((response:any)=>{
          response.DATA.forEach((element:any) => {
            this.all_blogs.push(element);
          });
        })
  }
  decreament_blog() {
      let body={}
      if (this.increament == 0) {
        body = {
          limit: 3,
          offset: 0
        }
      } else {
        body = {
          limit: 3,
          offset: this.increament-1
        }
      }
      this.data.get_all_blogs(body).subscribe((response:any)=>{
        response.DATA.forEach((element:any) => {
          this.all_blogs.push(element);
        });
      })
  }
  
  hello(x:any) {
    // alert(x)
    let body={}
       body = {
        limit: 3,
         offset: x
    }
    this.all_blogs=[]
    this.data.get_all_blogs(body).subscribe((response:any)=>{
      response.DATA.forEach((element:any) => {
        this.all_blogs.push(element);
      
      });
    })
  }

}

