import { Component, EventEmitter, Output, Inject, HostListener } from '@angular/core';
import { ViewportScroller, DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public activatedRoute: ActivatedRoute
  ) {}

  title = 'windmill';

  ngOnInit() {}


}
