<nav class="navbar navbar-expand-lg fixed-top navbar-dark " id="navbar">
  <div class="container-fluid">

    <!-- Brand -->
    <!-- <a class="navbar-brand" routerLink="['/home']" (click)="changeInNavigation('home')" target="_blank"> -->
    <a class="navbar-brand"  href="#" >
      <img class="brand-img" src="assets/images/windmill12.png" alt="windmill logo">
    </a>

    <!-- Collapse -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Links -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">

      <!-- Left -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#">Home
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Product
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
            <a class="dropdown-item"(click)="changeInNavigationHome('second-banner')" href="#">Optima</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Use Cases
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
            <!-- <a class="dropdown-item" (click)="changeInNavigationHome('use')"> Migration Serv</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('fifth-banner')"> DevOps Serv</a> -->
            <a class="dropdown-item" (click)="changeInNavigationHome('use')">Software Service Enterprises</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('use')"> Enterprise For Multicloud</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('use')"> Individual Contributor</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('use')"> Cloud Aspiring MSME</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('use')"> ITES Managed Enterprises</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('use')"> Web Hosting Enterprises</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Resources
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
            <a class="dropdown-item" (click)="changeInNavigationHome('price-compare')"> Cloud Price</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('features')"> Vision</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('brochure-1')">Brochure</a>
            <!-- <a class="dropdown-item" (click)="changeInNavigationHome('blog')">Blogs</a> -->
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
           Company
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
            <a class="dropdown-item" (click)="changeInNavigationHome('contact-us')"> Contact us</a>
            <a class="dropdown-item" (click)="changeInNavigationHome('about-us')"> About us</a>
          </div>
        </li>
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          LogIn
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
            <a class="dropdown-item"  href="{{signInUrl}}">Sign In</a>
          </div>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" href="{{signInUrl}}">LogIn
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
 