import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public viewPortScroller: ViewportScroller,
    public route: Router,
    public http: HttpClient,) { }

    hover:boolean = false

  
    @Output() eventShowIsFalse = new EventEmitter<any>();
  
    changeInNavigationHome(type: any) {
      this.route.navigate(['home'])
      this.changeInNavigation(type)
    }

  changeInNavigation(type: any) {
    console.log(type);
    if (type == 'features')
    {
      //alert("you are clicking Vision")
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'contact-us')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'zero-banner')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'second-banner')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'third-banner')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'fourth-banner')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'fifth-banner')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'blog')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else if (type == 'use')
    {
      this.viewPortScroller.scrollToAnchor(type);
    }
    else
      this.route.navigate([type])
  }

  ngOnInit() {
  }

}
