

<section class="price" id="home">
    <div class=" hero-overlay"></div>
    <div class="hero-second-overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center position-relative" style="top: 50%">
                    <h2 class="ease delay fw-400"></h2>
                </div>
            </div>
        </div>
    </div>
</section>





<div class="container-fluid pricingBox">

    <div fxLayout="row" fxLayoutAlign="center center" >
        
            <img src="./assets/images/cloudwindmill-image-3.jpg" class="img-fluid" style="margin: auto;" width="1000"
                height="800">
            <!--div class="centered">
                <h1><b>CloudWindmill – The Cloud Management Tool</b></h1>
            </div>
            <div class="bottom">
                <h3><b>A Differentiator</b> </h3>

            </div-->
        
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" >
        
            <h1><b>CloudWindmill – The Cloud Management Tool</b></h1>
            
         
          
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" >
        
        
        
        <h3><b>A Differentiator</b> </h3>
      
</div>
    
        <!--div class="col-md-9 col-sm-12 pr-2 my-5 ml-5 pl-3">
        </div-->

        <!-- card row -->
        <div fxLayout="row" fxLayoutAlign="start center" class="head1">
            <h2>Overview</h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <p><b>CloudWindmill</b> is evangelizing to control cloud cost, easy monitoring and tracking cloud use,
                better and easy way to scheduling cloud machines, AI bases scoring for organization cloud
                resource usability. <b>CloudWindmill</b> is extending features to Apps/Services deployment on
                standalone and Kubernetes environment for auto pipeline deployment in CI/CD. 
            </p><br>
       </div>
       <div fxLayout="row" fxLayoutAlign="start center" style="margin-left:50px;
       margin-right:50px;">
            <h2>Business Values </h2>
       </div>
            <div class="row" style="margin-left:25px;margin-right:25px;">
                <div class="col-md-6">
                    <div class="media">
                        <div class="media-left">
                            <div class="feature-icon">
                                <img src="./assets/images/web.png" class="img-fluid" />
                            </div>
                        </div>
                        <div class="media-body text-left">
                            <p> <b>Zero Cost for unused resource</b>
                                AI based resource scheduling prevent unused resource to stop and no impact on
                                productivity
                            </p>
                        </div>
                    </div>

                    <div class="media" style="margin-top:10px">
                        <div class="media-left">
                            <div class="feature-icon">
                                <img src="./assets/images/offline.png" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class=" media-body text-left">
                            <p> <b>Zero CLI</b>
                                Complete UI drag and drop operation. Abstract Everything
                            </p>
                        </div>
                    </div>
                    <div class="media" style="margin-top:20px">
                        <div class="media-right">
                            <div class="feature-icon">
                                <img src="./assets/images/wizard.png" class="img-fluid" />
                            </div>
                        </div>
                        <div class=" media-body text-left">
                            <p><b>Zero Trust leads full security</b>
                                Zero trust to external world. Roles and permissions are mapped to enterprise identity
                                management
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 ">
                    <div class="media ">
                        <div class="media-left">
                            <div class="feature-icon">
                                <img src="./assets/images/technology.png" class="img-fluid">
                            </div>
                        </div>
                        <div class="media-body text-left">
                            <p><b>Zero human latency</b>
                                Automate and orchestrate all apps as pipeline (Build, Test, Deployment)
                            </p>
                        </div>
                    </div>
                    <div class="media" style="margin-top:35px">
                        <div class="media-right">
                            <div class="feature-icon">
                                <img src="./assets/images/gps.png" class="img-fluid" />
                            </div>
                        </div>
                        <div class="media-body text-left">
                            <p><b>Zero Appliances</b>
                                Multi cloud support eliminate need to have local infra
                            </p>
                        </div>
                    </div>

                    <div class="media" style="margin-top:20px">
                        <div class="media-right">
                            <div class="feature-icon">
                                <img src="./assets/images/lock.png" class="img-fluid" alt="" />
                            </div>
                        </div>
                        <div class=" media-body text-left">
                            <p><b>Zero Capacity Constraints</b>
                                All provisioning via automated Scaling, Aging and Suspension policy
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <p>The <b>CloudWindmill</b> architecture design translate into capabilities that provides features and
                benefit that make it easy to choose cloud management tools as SaaS. Policy driven intent based
                design gives more control on cost which was not controlled earlier for infrastructure.
                Enterprises has freedom to use multi cloud and microservice environment as per budget and
                schedule. <b>CloudWindmill</b> is not using native cloud services to minimize customer cloud cost,
                we offer propriety tools. Each stage has analytics and notification integration to take lead
                time for action.
            </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" >
            <img src="./assets/images/windmillarchitecture.png" class="img-fluid" style="margin: auto;" width="1000"
                height="800">
                </div>
            <h4>Architecture view of CloudWindmill platform </h4>
            <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <p>Extensive detail architecture of <b>CloudWindmill</b> provide flexibility of pipeline deployment in Dev,
                QA
                and production environment. IT admin can take action on underused VMs to control cost in public cloud
                deployment, CloudWindmill provides AI based score to organization which indicate how effectively cloud
                resources are used. Kubernetes based Pipeline deployment is automated so if any wrong deployment is
                reported in between, it rolls back automatically.
            </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <p>Role base security is enabled in <b>CloudWindmill</b> to manage SaaS effectively. It provides enterprises
                IAM
                integration for single sign on. Each pipeline stage is integrated with approval workflow to manage
                resources effectively.
            </p>
            </div> 
            <div fxLayout="row" fxLayoutAlign="start center" style="margin-left:50px;
       margin-right:50px;">
            <h2>Why CloudWindmill </h2>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
            <img src="./assets/images/whycloudwindmill.png" class="img-fluid" style="margin: auto;" width="1000"
                height="800">
                </div> <br>
            <br><br>
            <div class="row" style="margin-left:25px;margin-right:25px;">
                <div class="col-md-6">
                    <div class="media">

                        <div class="media-body text-left">
                            <h5 class="media-heading  long "><b>I N T E N T &nbsp; D R I V E N &nbsp; D E S I G N </b> </h5>
                            <div class="justify">

                                <p> Self service portal empower user to limited functionality and his behavioural
                                    action.
                                    IT admin can restrict user as per their roles and resources can be made available if
                                    they make request manually. Intend based business application pipeline will provide
                                    resources as per business need and not as per user role. e.g: If the intent is to
                                    provide low cost resource/restriction to QA business pipeline instead with
                                    production
                                    business pipeline then policies and deployment type create environment in
                                    <b>CloudWindmill</b> to provide effective outcome
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 ">
                    <div class="media ">

                        <div class="media-body text-left">
                            <h5 class="media-heading  long"><b>T I M E &nbsp; T O &nbsp; V A L U E </b></h5>
                            <div class="justify">
                                <p>Many cloud management tools claim rapid deployment but actually are not able to
                                    do so because of their pre and post configuration to make application live.
                                    <b>CloudWindmill</b> pipeline is contracted from bottom up approach to make sure
                                    that
                                    the tiniest entity in pipeline does not create chaos.
                                </p>
                                <p><b>CloudWindmill</b> has catalogue of services which are tested in standalone VM and
                                    microservices environment. <b>CloudWindmill</b> deployment manager is maintaining
                                    states of deployment and event base reporting to pipeline. Structure deployment
                                    of pipeline is fast and if something goes wrong in between it has auto rollback.
                                </p>
                                <p><b>CloudWindmill</b> is available for Demo to prove its credibility for fast
                                    deployment tool.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <p>The <b>CloudWindmill</b> provides extensive and elaborated features set to enrich Cost Controll,
                Kubernetes services deployment and Continuous Delivery as Service platform. It has extensive in
                build service catalogue with application templates. The <b>CloudWindmill</b> is proposed as SaaS
                with detailed dashboard as per user roles. For example, cost is the main pain point for IT admin
                so dashboard provides details of each VM’s cost in pipeline. It will provide policy based flexibility
                to take action such as- on aging, scaling and suspension of VMs or services.
            </p> 
            </div>
            <div class="row" style="margin-left: 25px;margin-right:25px;">
                <div class="col-md-6">
                    <div class="media">

                        <div class="media-body text-left">
                            <h5 class="media-heading  long "><b>V E N D O R &nbsp; &nbsp; A G N O S T I C </b>
                            </h5>
                            <div class="justify">
                                <p> The <b>CloudWindmill</b> is vendor agnostic solution, it enable IT organization to
                                    choose best in class
                                    technologies for their infra deployment. Organization can create their on service
                                    catalogue and have
                                    freedom to use any public, private and/or hybrid cloud. <b>CloudWindmill</b> Kubernetes
                                    based service deployment
                                    is independent to native cloud service (EKS, AKS,GKE)

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 ">
                    <div class="media ">
                        <div class="media-body text-left">
                            <h5 class="media-heading  long"><b>M U L T I &nbsp; &nbsp; T E N A N T</b> </h5>
                            <div class="justify">
                                <p>The <b>CloudWindmill</b> is completely SaaS platform which support multi tenancy. Multi
                                    tenancy is ensuring
                                    the other group cannot access each other’s resources. The <b>CloudWindmill</b> is
                                    offering multi tenancy to
                                    integrated enterprise AIM/AD/LDAP to maintain their own application template, network,
                                    service
                                    catalogue, OS template and rate program.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <br>
            <div class="row" style="margin-left: 25px;margin-right:25px;">
                <div class="col-md-6">
                    <div class="media">

                        <div class="media-body text-left">
                            <h5 class="media-heading  long "><b>L I C E N S E &nbsp; M A N A G E M E N T </b>
                            </h5>
                            <div class="justify">
                                <p> The <b>CloudWindmill</b> is completely SaaS offering to price on the basis of service
                                    instances. It has 3
                                    variants of licenses (Individuals, Small Team, Organization). Each license is bundled with
                                    different features
                                    respectively.
                                </p>
                                <p> Tracking licenses are tricky for enterprises along with how effectively are used e.g.
                                    enterprises might have
                                    more idle licenses with respect to service instances consumption. The <b>CloudWindmill</b>
                                    provides analytics
                                    and notification for unused licenses.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 ">
                    <div class="media ">

                        <div class="media-body text-left">
                            <h5 class="media-heading  long"><b>S E R V I C E &nbsp; C A T E L O G U E</b>

                            </h5>
                            <div class="justify">
                                <p>The <b>CloudWindmill</b> is equipped with pre defined services to build an application. IT
                                    admin will
                                    get option to create new services as per enterprise need. Once its services is offered for
                                    an environment
                                    such as VMs or Microservice, it is possible for user to see pricing for each environment.
                                </p>
                                <p>The process of creating new service is done in <b>CloudWindmill</b> UI. Admin can create
                                    service with
                                    pre defined component with their appropriate life cycle.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <br>
            <div class="row" style="margin-left: 25px;margin-right:25px;">
                <div class="col-md-6">
                    <div class="media">

                        <div class="media-body text-left">
                            <h5 class="media-heading  long "><b>I N F R A &nbsp; A S &nbsp; A &nbsp; C O D E </b>
                            </h5>
                            <div class="justify">
                                <p> Once infra is deployed, it does not mean that developer cannot access their service
                                    node.
                                    The <b>CloudWindmill</b> will enable SSH connect on each service node to run custom
                                    shell
                                    script, terraform, ansible scripts.

                                </p>
                                <p> User has privilege to make customized update while service is deployed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 ">
                    <div class="media ">

                        <div class="media-body text-left">
                            <h5 class="media-heading  long"><b>C O S T &nbsp; C O N T R O L </b>
                            </h5>
                            <div class="justify">
                                <p>As soon as you have created service , it shows you usage plan on various public cloud as
                                    per deployment
                                    type. <b>CloudWindmill</b> will also suggest VMs and/or Microservices deployment usage
                                    plan to take decision
                                    for cost control. The <b>CloudWindmill</b> is using AI/ML based engine to suggest best
                                    practice for your
                                    application deployment to control cost. It has intelligent notification and analytics
                                    engine to warn IT
                                    admin for under utilized VMs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <br>
            <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <h5 class="media-heading  long"><b>C l o u d W I n d m i l l</b> &nbsp; c o n t r o l &nbsp; I T &nbsp; s e l f
                &nbsp; s e r v i c e &nbsp; b e n e f i t
            </h5>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
            <img src="./assets/images/cloudwindmillselfservice.png" class="img-fluid" style="margin: auto;" width="1000" height="800">
         </div>
            
            <h6><b>Summary</b> </h6>
            <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <p>The <b>CloudWindmill</b> is proven reality for “Infrastructure on Palm”, organization can manages their cloud
                infra as per need.
            </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <p>The <b>CloudWindmill</b> is one stop solution for software development enterprises, self IT managed
                enterprises (includes many applications), enterprises managing shared web service and last but not the
                least for free lancer. The <b>CloudWindmill</b> enables software developer to focus on development and not
                on deployment. Enterprises can save cost on development to provide effective infra as per intent. Policy
                based deployment control orchestrate infra as per need/schedule/load.
            </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" class="head1">
            <p>Role base analytics and dashboard has elevation view on usage plan, cost, infra, progress, rollback etc.</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
            <img src="./assets/images/cloudwindmillsummery.png" class="img-fluid" style="margin: auto;" width="1000" height="800">
            </div>
            <br><br>
    

</div>